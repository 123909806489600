<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <h1>User Accounts</h1>
    <p>Set your standard business hours. These times will be used for your appointment calendar.</p>
    <hr>
    <div v-if="users">
      <div class="box bunya-box card" v-for="(user, index) in users" v-bind:key="index">
        <router-link :to="{ name: 'settings-users-show', params: { id: user.id }}">
          <div>
            <div class="level is-mobile component-level-margin">
              <div class="level-left">
                <div class="level-item">
                  <h6 class="subtext">{{ user.name }}</h6>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <h6 class="subtext">{{ user.time_zone.time_zone }}</h6>
                </div>
              </div>
            </div>
            <div class="level is-mobile">
              <div class="level-left">
                <div>
                  <div class="block level-item">
                    <h4 class="no-line-height"><span>{{ user.display_name }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <hr>
    </div>
    <div v-else>
      <b-message type="is-danger">
        <strong>No users have been added.</strong>
      </b-message>
      <hr>
    </div>
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <router-link :to="{ name: 'settings-users-create' }" class="button is-primary is-fullwidth-mobile">Add user</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  @import '../../scss/_theme-default.scss';

  .card a {
    color: $dark;
  }

  .card a:hover {
    color: lighten($dark, 20);
  }

  .card {
    background-color: #F9F9F9;
  }

  .subtext {
    color: lighten($dark, 40);
  }

</style>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'Users',
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Users', route: 'settings-users' }
      ]
    }
  },
  computed: {
    users () {
      return this.$store.getters['settings/users']
    }
  },
  mounted () {
    this.$store.dispatch('settings/fetchUsers')
      .then(() => {})
      .catch((err) => {
        this.$buefy.toast.open({
          message: `Error: ${err.message}`,
          type: 'is-danger',
          duration: 4000,
          queue: false
        })
      })
  },
  components: { Breadcrumbs }
}
</script>
